<template>
  <div class="flex flex-col font-Inter px-10">
    <Loading v-if="loadingFull" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Devis N° {{ estimate.number }}
          </h2>
        </div>
        <div>
          <el-button
            icon="el-icon-printer"
            type="info"
            @click="exportEstimatePDF"
            >Imprimer</el-button
          >
          <el-button icon="el-icon-view" @click="handleView">Aperçu</el-button>
          <el-button
            v-if="estimate.status === 'DRAFT'"
            type="primary"
            :loading="validLoanding"
            @click="handleValidEstimante"
            >Confirmer</el-button
          >

          <el-button
            v-if="estimate.status === 'OPEND'"
            type="primary"
            :loading="validLoanding"
            @click="handleOpenTransfDialog"
            >Accpter et Transformer</el-button
          >

          <el-dropdown class="mx-1" split-button @command="handleAction">
            Action
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                command="update"
                :disabled="
                  estimate.status !== 'OPEND' && estimate.status !== 'DRAFT'
                "
              >
                <i class="el-icon-document-copy"></i>Modifier
              </el-dropdown-item>
              <el-dropdown-item command="copy">
                <i class="el-icon-edit"></i>Dupliquer</el-dropdown-item
              >
              <el-dropdown-item
                :disabled="
                  estimate.status !== 'OPEND' && estimate.status !== 'DRAFT'
                "
                command="cancel"
                ><i class="el-icon-delete"></i>Annuler
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="mb-10">
      <div class="flex flex-col mx-auto bg-white p-5 shadow">
        <div class="flex justify-between mb-4 items-start">
          <div class="flex flex-col w-1/2">
            <h1
              class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate mb-8"
            >
              {{ estimate.contact_name }}
            </h1>
            <div class="flex flex-col bg-gray-100 w-2/3 p-3">
              <div class="flex justify-between mb-2">
                <span class="text-gray-500">N°</span>
                <span class="text-blue-500">{{ estimate.contact_no }}</span>
              </div>
              <div class="flex justify-between mb-2">
                <span class="text-gray-500">Téléphone</span>
                <span>{{ estimate.contact_phone }}</span>
              </div>
              <div class="flex justify-between mb-2">
                <span class="text-gray-500">Email</span>
                <span>{{ estimate.email }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-500">Adresse</span>
                <span>{{ estimate.contact_street }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-500">Correspondant</span>
                <span
                  >{{ estimate.contact_firstname }}
                  {{ estimate.contact_lastname }}</span
                >
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-end items-end w-1/2">
            <div
              v-if="estimate.status === 'OPEND'"
              class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate mb-8 text-red-600"
            >
              Montant dû :
              {{ (estimate.total - estimate.amount_to_pay) | moneyFilter }}
              {{user.currency_code}}
            </div>
            <div
              v-else
              class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate mb-8"
            >
              Total :
              {{ estimate.total | moneyFilter }}
              {{user.currency_code}}
            </div>

            <div class="flex flex-col w-2/3 p-3">
              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Date création : </span>
                <span>{{ estimate.create_at }}</span>
              </div>

              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Date</span>
                <span>{{ estimate.date }}</span>
              </div>

              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Date d'echéance</span>
                <span>{{ estimate.due_date }}</span>
              </div>

              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Date de livraison</span>
                <span>{{ estimate.shipping_date }}</span>
              </div>

              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Statut</span>
                <Status :name="estimate.status" />
              </div>
              <div class="flex justify-between mb-3">
                <span class="text-gray-500">Condition de paiement</span>
                <div>
                  {{ estimate.terms }}
                </div>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-500 mr-3">Reférence</span>
                <p>{{ estimate.reference }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="customer-white">
          <el-table
            :data="estimate.line_items"
            style="width: 100%"
            class="mt-10"
          >
            <el-table-column prop="warehouse_code" label="CODE ENT" width="120">
              <template slot-scope="{ row }">
                <span class="text-blue">{{ row.warehouse_code }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="article_sku" label="N°ARTICLE" width="160">
            </el-table-column>

            <el-table-column
              prop="article_name"
              label="DÉSIGNATION"
              min-width="250"
            >
              <template slot-scope="{ row }">
                <div class="flex flex-col justify-start">
                  <div class="mb-1">{{ row.article_name }}</div>

                  <p class="text-gray-400">{{ row.description }}</p>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="price"
              align="right"
              label="PRIX"
              width="150"
            >
              <template slot-scope="{ row }">
                <span>{{ row.price_sales | moneyFilter }}</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="quantity"
              align="center"
              label="QUANTITÉ"
              width="150"
            >
              <template slot-scope="{ row }">
                <div class="input-transparent">
                  <span>{{ row.quantity }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="taxe"
              align="center"
              label="REMISE "
              width="120"
            >
              <template slot-scope="{ row }">
                <span v-if="row.percent_discount"
                  >{{ row.rate_discount }} %</span
                >
                <span v-if="!row.percent_discount">{{ row.discount }} F </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="taxe"
              align="center"
              label="TAXE "
              width="80"
            >
              <template slot-scope="{ row }">
                <span>{{ row.percent_tax }} %</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="price"
              align="right"
              label="TOTAL"
              width="180"
            >
              <template slot-scope="{ row }">
                <span>{{ row.total | moneyFilter }} </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="flex justify-between items-start mb-2 border-b my-8">
          <div class="flex flex-col w-2/3">
            <div class="flex flex-col">
              <span class="text-gray-500 mb-8"
                >Note d'information sur la facture :</span
              >
              <p>{{ estimate.note }}</p>
            </div>
          </div>
          <div class="flex flex-col w-1/3 justify-start">
            <div class="flex justify-between">
              <span>Total HT:</span>
              <span> {{ estimate.sub_total | moneyFilter }} </span>
            </div>
            <div class="flex justify-between pt-2 mt-4 text-gray-500">
              <span class="mr-4"
                >Remise
                <span v-if="estimate.percent_discount"
                  >({{ estimate.rate_discount }} %)</span
                >
                :</span
              >
              <span>{{ estimate.discount | moneyFilter }} </span>
            </div>
            <div class="flex justify-between mt-4 text-gray-500">
              <span class="mr-4">Taxe ({{ estimate.percent_tax }}%):</span>
              <span>{{ estimate.total_tax | moneyFilter }} </span>
            </div>
            <div class="flex justify-between mt-4 text-gray-500">
              <span class="mr-4">Autre coût:</span>
              <span>{{ estimate.shipping_cost | moneyFilter }} </span>
            </div>
            <div
              class="flex justify-between font-medium leading-7 text-gray-900 py-4 border-b"
            >
              <span>Total :</span>
              <span>
                {{ estimate.total | moneyFilter }}
                {{ estimate.currency }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <transform-quote
      :number="estimate._id"
      :dialogVisible="dialogTransVisible"
      @closeDialog="handleCloseTransfDialog"
    />
    <!-- View PDF -->
    <PDFView :url="urlPDF" :visible="dialogViewPDF" @close="handleClose" />
  </div>
</template>

<script>
import { exportQuotePDF } from "@/api/export";
import {
  getDocument,
  copyDocument,
  cancelDocument,
  validDocument,
} from "@/api/sales2";
import Loading from "@/components/Loanding/Full";

import { formatDate, getDayRetard } from "@/utils";
import { toThousandFilter } from "@/Filters";
import TransformQuote from "@/components/TransformQuote";
import Status from "@/components/Status";
import PDFView from "@/components/PDF/view";
import {mapGetters} from "vuex";

export default {
  name: "estimateFiche",
  filters: {
    filterTime: formatDate,
    dayRetard: getDayRetard,
    moneyFilter: toThousandFilter,
  },
  components: { Loading, Status, "transform-quote": TransformQuote, PDFView },
  data() {
    return {
      typeDoc: "QUOTE",
      urlPDF: null,
      dialogViewPDF: false,
      loadingFull: false,
      dowloandLoading: false,
      validLoanding: false,
      totalItem: 0,
      dialogTransVisible: false,
      activeName: "estimate",
      dialogPaymentestimate: false,
      labelPosition: "top",
      paymentestimateLoading: false,
      listPaymentLoading: false,
      canceleLoading: false,
      copyLoading: false,
      estimate: {
        comment: null,
        _id: "",
        billing_to: "",
        contact_id: "",
        contact_name: "",
        contact_no: "",
        contact_phone: "",
        contact_street: "",
        currency: "",
        date: "",
        reference: "",
        terms: "",
        due_date: "",
        shipping_cost: 0.0,
        shipping_to: "",
        status: "",
        percent_discount: 0,
        percent_tax: 0,
        total: 0.0,
        total_tax: 0.0,
        total_discount: 0.0,
        sub_total: 0.0,
        total_profit: 0.0,
        line_items: [],
      },
      accounts: [],

      paymentestimate: {
        customer_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        amount: 0,
        account_number: "",
        accounts: [],
        estimates: [],
      },
      paiments: [],
      rules: {
        payment_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        payment_no: [
          {
            required: true,
            message: "La numéro paiement est obligatoire",
            trigger: "bluer",
          },
        ],

        amount: [
          {
            required: true,
            message: "Le montant est obligatoire",
            trigger: "blur",
          },
        ],
        account_number: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const id = this.$route.params && this.$route.params.id;
      this.loadingFull = true;
      await getDocument(id, this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            this.loadingFull = false;
            this.estimate = Object.assign({}, res.data);
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleCanceledEstimate() {
      const h = this.$createElement;
      this.$msgbox({
        title: "DEVIS N° " + this.estimate.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler le devis ?  "),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelDocument(this.estimate._id, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);
                  this.estimate.status = "CANCELED";
                  this.$message({
                    type: "success",
                    message: "Le devis a été annulé",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCopyestimate() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Devis N° " + this.estimate.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment dupliquer ce devis  ?"),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            copyDocument(this.estimate._id, this.typeDoc)
              .then((res) => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.$message({
                    message: "Le devis a été dupliqué",
                    type: "success",
                  });

                  this.$router.push(`/sales/quote/${res.data._id}`);
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },

    handleValidEstimante() {
      this.validLoanding = true;
      validDocument(this.estimate._id, this.typeDoc)
        .then(() => {
          setTimeout(() => {
            this.$message({
              message: "Le devis a été validé",
              type: "success",
            });
            this.estimate.status = "OPEND";
            this.validLoanding = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          this.validLoanding = false;
          console.log(err);
        });
    },
    handleCloseTransfDialog(e) {
      this.dialogTransVisible = e;
    },
    handleOpenTransfDialog() {
      this.dialogTransVisible = true;
    },
    handleAction(e) {
      switch (e) {
        case "update":
          this.$router.push(`/sales/quote/update/${this.estimate._id}`);
          break;
        case "copy":
          this.handleCopyestimate();
          break;
        case "cancel":
          console.log(e);
          this.handleCanceledEstimate();
          break;
      }
    },
    exportEstimatePDF() {
      exportQuotePDF(this.estimate._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleView() {
      this.dialogViewPDF = true;
      exportQuotePDF(this.estimate._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "application/pdf" })
          );
          this.urlPDF = url;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleClose() {
      this.dialogViewPDF = false;
    },
  },
};
</script>

<style></style>
